import { Form, Input, Button, Typography } from 'antd'
import { UserOutlined, LockOutlined, GoogleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PublicPage from '../components/Layout/PublicPage'
import '../translations/i18n'
import { useAuth } from '../contexts/AuthContext'
import { FormContainer } from '../components/SharedComponents'
import { ROUTES } from '../utils/constants'
import Translate from '../components/Translate'
import { handleError } from '../utils/logger'

const { Title } = Typography

const LoginPage = () => {
  const { signInWithGoogle, login } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onFinish = async ({ username, password }) => {
    try {
      await login(username, password)
      navigate(ROUTES.ACCOUNT)
    } catch (error) {
      handleError({ error, message: 'login' })
    }
  }

  return (
    <PublicPage>
      <FormContainer>
        <Title level={2}>
          <Translate name="login" />
        </Title>
        <Form name="login" onFinish={onFinish}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: t('usernameMissing'),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('username')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('passwordMissing'),
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('password')}
            />
          </Form.Item>
          <Form.Item>
            <a className="login-form-forgot" href="/forgot-password">
              <Translate name="forgotPassword" />
            </a>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
            >
              <Translate name="login" />
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              type="secondary"
              htmlType="button"
              className="login-form-button"
              icon={<GoogleOutlined />}
              onClick={() => {
                signInWithGoogle()
                  .then(() => {
                    navigate(ROUTES.ACCOUNT)
                  })
                  .catch((e) =>
                    handleError({ error: e, message: 'GoogleLoginFailed' })
                  )
              }}
              block
            >
              <Translate name="loginWithGoogle" />
            </Button>
          </Form.Item>
          <Form.Item>
            <Translate name="or" />
            <a href="/registration">
              <Translate name="toRegister" />
            </a>
          </Form.Item>
        </Form>
      </FormContainer>
    </PublicPage>
  )
}

export default LoginPage
