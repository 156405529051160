import { useState } from 'react'
import { Input, List, Space } from 'antd'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { where } from 'firebase/firestore'
import PrivatePage from '../components/Layout/PrivatePage'
import { JOB_STATUSES, NEW_ENTITY_ID, ROUTES } from '../utils/constants'
import '../translations/i18n'
import { StyledTitle } from '../components/SharedComponents'
import { useJobs } from '../hooks/dataLayer'
import { useUser } from '../contexts/UserContext'
import { useAuth } from '../contexts/AuthContext'
import { isAdmin, isGraphic, isCustomer } from '../utils/helper'
import Translate from '../components/Translate'

const JobsPage = () => {
  const { userData } = useUser()
  const { currentUser } = useAuth()
  const { data: jobs } = useJobs(
    isAdmin(userData)
      ? null
      : isGraphic(userData)
      ? where('graphicUid', '==', currentUser.uid)
      : where('authorUid', '==', currentUser.uid)
  )
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const jobsToDisplay = (jobs || []).filter(
    ({ title, status, counter }) =>
      (status !== JOB_STATUSES.CANCELLED ||
        (isAdmin(userData) && counter > 0)) &&
      (title || '').toLowerCase().indexOf((search || '').toLowerCase()) === 0
  )

  return (
    <PrivatePage
      header={t('jobs')}
      headerEndContent={
        isCustomer(userData) && (
          <StyledTitle
            level={4}
            onClick={() =>
              navigate(generatePath(ROUTES.JOB, { id: NEW_ENTITY_ID }))
            }
          >
            {t('newJob')}
          </StyledTitle>
        )
      }
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder={t('shared.searchPlaceholder')}
        />
        <List
          itemLayout="horizontal"
          dataSource={jobsToDisplay}
          bordered
          size="small"
          renderItem={({ id, title, description, status }) => (
            <List.Item
              key={id}
              onClick={() => navigate(generatePath(ROUTES.JOB, { id }))}
            >
              <List.Item.Meta title={title} description={description} />

              <div>
                <Translate name={`jobStatuses.${status}`} />
              </div>
            </List.Item>
          )}
        />
      </Space>
    </PrivatePage>
  )
}

export default JobsPage
