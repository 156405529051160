import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAccount } from '../hooks/dataLayer'
import { useAuth } from './AuthContext'

const UserContext = createContext({
  userData: null,
  refetchUserData: () => Promise.resolve(),
  loading: false,
})

export const useUser = () => useContext(UserContext)

const UserContextProvider = ({ children }) => {
  const { currentUser } = useAuth()
  const [currentUserData, setCurrentUserData] = useState(null)
  const { data: userData, loading, refetch } = useAccount(null)

  useEffect(() => {
    if (currentUser?.uid) {
      refetch(currentUser.uid)
    }
  }, [currentUser])

  useEffect(() => {
    if (userData) {
      setCurrentUserData(userData)
    }
  }, [userData])

  const value = {
    userData: currentUserData,
    refetchUserData: () => refetch(currentUser?.uid),
    loading,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
