import { useTranslation } from 'react-i18next'
import { Button, Form, Avatar, Typography, Upload } from 'antd'
import { useEffect, useState } from 'react'
import { UserOutlined, UploadOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { LeftFormContainer } from '../components/SharedComponents'
import '../translations/i18n'
import PrivatePage from '../components/Layout/PrivatePage'
import { useAuth } from '../contexts/AuthContext'
import { useSetAccount, useAccount } from '../hooks/dataLayer'
import Translate from '../components/Translate'
import useFileUpload from '../hooks/useFileUpload'
import { validateFileBeforeUpload } from '../utils/helper'
import { FormCheckboxInput, FormTextInput } from '../components/FormInputs'
import { useUser } from '../contexts/UserContext'
import LoadingContainer from '../components/LoadingContainer'
import { ROUTES, USER_TYPES } from '../utils/constants'
import EnsureRole from '../components/EnsureRole'

const { Title } = Typography

const AvatarContainer = styled.div`
  padding: 0 0 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AccountPage = () => {
  const { currentUser } = useAuth()
  const { refetchUserData } = useUser()
  const { t } = useTranslation()
  const [accountData, setAccountData] = useState({})
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { data, loading, error } = useAccount(currentUser.uid)
  const { save, loading: isSaving } = useSetAccount(currentUser.uid)
  const { upload, loading: isUploading } = useFileUpload()

  useEffect(() => {
    if (data) {
      setAccountData(data)
      form.setFieldsValue(data)
    }
  }, [data])

  const onFinish = async () => {
    await save({ ...accountData, email: currentUser.email })
    refetchUserData()
  }

  return (
    <PrivatePage header={t('account')}>
      <LoadingContainer loading={loading} error={error}>
        <LeftFormContainer>
          <AvatarContainer>
            <Avatar
              size={64}
              icon={accountData?.avatar !== undefined ? '' : <UserOutlined />}
              src={accountData?.avatar !== undefined ? accountData?.avatar : ''}
            />
            <Upload
              beforeUpload={validateFileBeforeUpload}
              showUploadList={false}
              customRequest={async ({ file }) => {
                const { url } = await upload(file)
                setAccountData({ ...accountData, avatar: url })
              }}
            >
              <Button icon={<UploadOutlined />} loading={isUploading}>
                <Translate name="uploadImage" />
              </Button>
            </Upload>
          </AvatarContainer>
          <Form
            form={form}
            name="account"
            onFinish={onFinish}
            onValuesChange={(_, values) =>
              setAccountData({ ...accountData, ...values })
            }
          >
            <FormTextInput name="firstName" required />
            <FormTextInput name="lastName" required />
            <EnsureRole role={USER_TYPES.GRAPHIC}>
              <FormTextInput name="portfolioURL" />
              <FormTextInput name="icalLink" required />
            </EnsureRole>

            <Form.Item>
              <Title level={5}>
                <Translate name="address" />
              </Title>
            </Form.Item>

            <FormCheckboxInput name="isCompany" />

            {accountData?.isCompany === true && (
              <>
                <FormTextInput name="companyName" required />
                <FormTextInput name="companyID1" required />
                <FormTextInput name="companyID2" required />
                <FormTextInput name="companyID3" />
              </>
            )}

            <FormTextInput name="addressLine1" required />
            <FormTextInput name="addressLine2" />

            <FormTextInput name="zipCode" required />
            <FormTextInput name="city" required />
            <FormTextInput name="country" required />

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={isSaving}>
                {t('save')}
              </Button>
            </Form.Item>
          </Form>
          {currentUser.providerData[0].providerId === 'password' && (
            <Button
              type="link"
              onClick={() => {
                navigate(ROUTES.CHANGE_PASSWORD)
              }}
            >
              <Translate name="changePasswordPage.title" />
            </Button>
          )}
        </LeftFormContainer>
      </LoadingContainer>
    </PrivatePage>
  )
}

export default AccountPage
