const COLORS = {
  DARK: '#001529e3',
  WHITE: '#ffffff',
}

const FONT_SIZES = {}

const SPACES = {}

export { COLORS, FONT_SIZES, SPACES }
