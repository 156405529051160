import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { db } from '../utils/firebase'
import { handleError } from '../utils/logger'

const useQueryFirebase = (coll, ...args) => {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)

  const loadData = async () => {
    try {
      const q = query(
        collection(db, coll),
        orderBy('created', 'desc'),
        ...args.filter(Boolean)
      )

      onSnapshot(q, (querySnapshot) => {
        setData(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        )
      })
    } catch (err) {
      handleError({ error: err, message: 'fetchError' })
      setError(err)
      throw err
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return { data, error }
}

export default useQueryFirebase
