import { Typography, Layout } from 'antd'
import styled from 'styled-components'
import { COLORS } from '../Theme'

export const FormContainer = styled.div`
  max-width: 340px;
  margin: auto;
  padding: 20px;
  text-align: center;
`

export const LeftFormContainer = styled.div`
  max-width: 480px;
`

export const StyledTitle = styled(Typography.Title)`
  color: ${COLORS.WHITE} !important;
  margin: 0 !important;
`

export const StyledFooter = styled(Layout.Footer)`
  text-align: center;
`
