import Translate from './Translate'

const LoadingContainer = ({ children, loading, error }) => {
  return error ? (
    <Translate name="shared.error" />
  ) : loading ? (
    <Translate name="shared.loading" />
  ) : (
    children
  )
}

export default LoadingContainer
