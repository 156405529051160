import { Form, Input, Button, Typography } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LockOutlined } from '@ant-design/icons'
import { FormContainer } from '../components/SharedComponents'
import PublicPage from '../components/Layout/PublicPage'
import '../translations/i18n'
import { useAuth } from '../contexts/AuthContext'
import { notifySuccess } from '../utils/notifications'

const { Title } = Typography

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ResetPasswordPage = () => {
  const { t } = useTranslation()
  const { resetPassword } = useAuth()

  const query = useQuery()
  const navigate = useNavigate()

  const onFinish = ({ password }) => {
    resetPassword(query.get('oobCode'), password).then(() => {
      notifySuccess({
        message: t('passwordResetSuccessTitle'),
      })
      navigate(query.get('continueUrl'))
    })
  }

  return (
    <PublicPage>
      <FormContainer>
        <Title level={2}>{t('resetPassword')}</Title>
        <Form name="resetPassword" onFinish={onFinish}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('passwordMissing'),
              },
              () => ({
                validator(_, value) {
                  if (value.length < 6)
                    return Promise.reject(new Error(t('passwordMinLength')))
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('password')}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('resetPassword')}
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
    </PublicPage>
  )
}

export default ResetPasswordPage
