import { Col, Divider, Row } from 'antd'
import TitleTranslate from '../../components/TitleTranslate'
import Files from './Files'
import JobTimeline from './JobTimeline'

const PublicView = ({ id, job }) => {
  return (
    <Row gutter={[24, 16]}>
      <Col lg={{ span: 12 }} md={{ span: 24 }}>
        <TitleTranslate name="shared.detail" level={4} />
        <p>{job?.title}</p>
        <p>{job?.description}</p>
        <Divider />
        <TitleTranslate name="jobPage.files" level={4} />
        <Files jobId={id} hideUpload />
      </Col>
      <Col lg={{ span: 12 }} md={{ span: 24 }}>
        <TitleTranslate name="jobPage.timeline" level={4} />
        <JobTimeline jobId={id} />
      </Col>
    </Row>
  )
}

export default PublicView
