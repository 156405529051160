import { useUser } from '../contexts/UserContext'
import { USER_TYPES } from '../utils/constants'
import { isAdmin, isCustomer, isGraphic } from '../utils/helper'

const EnsureRole = ({ children, role }) => {
  const { userData } = useUser()

  const displayChildren =
    isAdmin(userData) ||
    (role === USER_TYPES.CUSTOMER && isCustomer(userData)) ||
    (role === USER_TYPES.GRAPHIC && isGraphic(userData))

  return displayChildren ? children : ''
}

export default EnsureRole
