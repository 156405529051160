import { Typography } from 'antd'
import '../translations/i18n'
import Translate from './Translate'

const { Title } = Typography

const TitleTranslate = ({ level, name, val }) => {
  return (
    <Title level={level}>
      <Translate name={name} val={val} />
    </Title>
  )
}

export default TitleTranslate
