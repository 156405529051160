import { Timeline } from 'antd'
import Translate from '../../components/Translate'
import { useUser } from '../../contexts/UserContext'
import { useJobLogs } from '../../hooks/dataLayer'
import { CLIENT_FACING_TIMELINE_EVENTS } from '../../utils/constants'
import { convertUnixToDateTime, isCustomer } from '../../utils/helper'

const TimeLineEvent = ({ log }) => (
  <>
    <div>
      <Translate name={`jobPage.events.${log.event}`} />
    </div>
    <div>{log.authorEmail}</div>
    <div>
      <Translate
        name="shared.intlDateTime"
        val={{
          val: convertUnixToDateTime(log.created.seconds),
          formatParams: {
            val: {
              second: 'numeric',
              minute: 'numeric',
              hour: 'numeric',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          },
        }}
      />
    </div>
  </>
)

const JobTimeline = ({ jobId }) => {
  const { data } = useJobLogs(jobId)
  const { userData } = useUser()

  return (
    <Timeline>
      {data
        .filter(
          ({ event }) =>
            !isCustomer(userData) ||
            CLIENT_FACING_TIMELINE_EVENTS.includes(event)
        )
        .map((log) => (
          <Timeline.Item key={log.id}>
            <TimeLineEvent log={log} />
          </Timeline.Item>
        ))}
    </Timeline>
  )
}

export default JobTimeline
