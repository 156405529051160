import { Layout } from 'antd'
import styled from 'styled-components'
import { StyledFooter } from '../SharedComponents'
import Translate from '../Translate'
import whiteLogo from '../../assets/logo-white.svg'

const { Header, Content } = Layout

const StyledCntent = styled(Content)`
  padding: 0 50px;
  margin-top: 64px;
`

const StyledHeader = styled(Header)`
  position: fixed;
  z-index: 1;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`

const PublicPage = ({ children }) => {
  return (
    <Layout>
      <StyledHeader>
        <img alt="logo" src={whiteLogo} style={{ height: '50%' }} />
      </StyledHeader>
      <StyledCntent className="site-layout">
        <div className="site-layout-content">{children}</div>
      </StyledCntent>
      <StyledFooter>
        <Translate name="footer" />
      </StyledFooter>
    </Layout>
  )
}

export default PublicPage
