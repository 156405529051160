import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { storage } from '../utils/firebase'
import { handleError } from '../utils/logger'
import { notifySuccess } from '../utils/notifications'

const useFileUpload = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [progress, setProgress] = useState(null)

  // eslint-disable-next-line consistent-return
  const upload = (file) => {
    try {
      setLoading(true)
      setError(null)

      return new Promise((resolve, reject) => {
        const fullName = `/files/${uuidv4()}/${file.name}`
        const storageRef = ref(storage, fullName)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            setProgress(prog)
          },
          (err) => {
            setError(err)
            reject(err)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (u) => {
              setProgress(100)
              notifySuccess({ message: 'upload' })
              resolve({ url: u, name: file.name, uid: file.uid, fullName })
            })
          }
        )
      })
    } catch (err) {
      handleError({ error: err, message: 'uploadError' })
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { upload, loading, progress, error }
}

export default useFileUpload
