import styled from 'styled-components'

const PulsatingContent = styled.div`
  max-width: 80%;
  animation: pulse 2s infinite;
  transform: scale(1);

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }
`

const SplashScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

const SplashScreenLoader = () => (
  <SplashScreen>
    <PulsatingContent>Loading..</PulsatingContent>
  </SplashScreen>
)

export default SplashScreenLoader
