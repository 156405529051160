import 'antd/dist/antd.min.css'
import AppRouter from './components/AppRouter'
import AuthContextProvider from './contexts/AuthContext'
import UserContextProvider from './contexts/UserContext'

function App() {
  return (
    <AuthContextProvider>
      <UserContextProvider>
        <AppRouter />
      </UserContextProvider>
    </AuthContextProvider>
  )
}

export default App
