import { generatePath } from 'react-router-dom'
import { DB_COLLECTIONS } from '../utils/constants'
import useDeleteFirebase from './useDeleteFirebase'
import useFetchFirebase from './useFetchFirebase'
import useQueryFirebase from './useQueryFirebase'
import useSaveFirebase from './useSaveFirebase'
import useSingleSnapshotFirebase from './useSingleSnapshotFirebase'

const useJob = (id) => useSingleSnapshotFirebase(DB_COLLECTIONS.JOBS, id)
const useJobs = (...filters) =>
  useQueryFirebase(DB_COLLECTIONS.JOBS, ...filters)
const useSetJob = (id) => useSaveFirebase(DB_COLLECTIONS.JOBS, id)

const useJobLogs = (id) => useQueryFirebase(`${DB_COLLECTIONS.JOBS}/${id}/logs`)
const useSetJobLog = (id) =>
  useSaveFirebase(generatePath(DB_COLLECTIONS.JOBLOGS, { id }))

const useJobFiles = (id) =>
  useQueryFirebase(
    generatePath(DB_COLLECTIONS.JOBFILES, {
      id,
    })
  )
const useDeleteJobFile = (jobId, id) =>
  useDeleteFirebase(
    generatePath(DB_COLLECTIONS.JOBFILES, {
      id: jobId,
    }),
    id
  )
const useSetJobFile = (jobId, id) =>
  useSaveFirebase(
    generatePath(DB_COLLECTIONS.JOBFILES, {
      id: jobId,
    }),
    id
  )

const useAccount = (id) => useFetchFirebase(DB_COLLECTIONS.PROFILES, id, {})
const useSetAccount = (id) => useSaveFirebase(DB_COLLECTIONS.PROFILES, id)
const useUsers = () => useQueryFirebase(DB_COLLECTIONS.PROFILES)

export {
  useJob,
  useJobs,
  useSetJob,
  useAccount,
  useSetAccount,
  useJobLogs,
  useSetJobLog,
  useJobFiles,
  useSetJobFile,
  useUsers,
  useDeleteJobFile,
}
