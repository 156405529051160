export const DB_COLLECTIONS = {
  JOBS: 'jobs',
  JOBLOGS: 'jobs/:id/logs',
  JOBFILES: 'jobs/:id/files',
  PROFILES: 'profiles',
}

export const ROUTES = {
  JOBS: '/jobs',
  JOB: '/jobs/:id',
  ACCOUNT: '/account',
  CHANGE_PASSWORD: '/change-password',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSSWORD: '/reset-password',
  USERS: '/users',
  USER: '/users/:id',
  PROFILE: '/profile/:id',
}

export const USER_TYPES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  GRAPHIC: 'graphic',
}

export const NEW_ENTITY_ID = 'new'

export const JOB_STATUSES = {
  DRAFT: 'draft',
  READY: 'ready',
  INPROGRESS: 'inprogress',
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
}

export const JOB_EVENTS = {
  CREATE: 'create',
  EDIT: 'edit',
  TIMER_START: 'timerStart',
  TIMER_STOP: 'timerStop',
  CLOSE: 'close',
  FINISH: 'finish',
  CANCEL: 'cancel',
  APPOINTMENT_CREATION: 'appointmentCreate',
  FILE_UPLOAD: 'fileUpload',
  FILE_DELETE: 'fileDelete',
}

export const CLIENT_FACING_TIMELINE_EVENTS = [
  JOB_EVENTS.CREATE,
  JOB_EVENTS.TIMER_START,
  JOB_EVENTS.TIMER_STOP,
  JOB_EVENTS.CLOSE,
  JOB_EVENTS.CANCEL,
  JOB_EVENTS.APPOINTMENT_CREATION,
  JOB_EVENTS.FINISH,
]
