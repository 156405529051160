import { notification } from 'antd'

const notifySuccess = ({ message, description, placement = 'bottomRight' }) => {
  notification.success({
    message,
    description,
    placement,
  })
}

const notifyError = ({ message, description, placement = 'bottomRight' }) => {
  notification.error({
    message,
    description,
    placement,
  })
}

const notifyWarning = ({ message, description, placement = 'bottomRight' }) => {
  notification.warning({
    message,
    description,
    placement,
  })
}

export { notifySuccess, notifyError, notifyWarning }
