import { useState } from 'react'
import { Avatar, Badge, Button, Divider, Drawer, Layout, Menu } from 'antd'
import styled from 'styled-components'
import { UserOutlined, MenuOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { where } from 'firebase/firestore'
import { JOB_STATUSES, ROUTES } from '../../utils/constants'
import { COLORS } from '../../Theme'
import Translate from '../Translate'
import { useAuth } from '../../contexts/AuthContext'
import { useUser } from '../../contexts/UserContext'
import { StyledFooter, StyledTitle } from '../SharedComponents'
import { isAdmin, isGraphic } from '../../utils/helper'
import { useJobs } from '../../hooks/dataLayer'
import SideBar from './SideBar'
import whiteLogo from '../../assets/logo-white.svg'
import blueLogo from '../../assets/logo-blue.svg'

const { Content, Header } = Layout

const CenteredFlex = styled.div`
  display: flex;
  justify-content: center;
`

const UserNameContainer = styled(CenteredFlex)`
  padding: 10px;
  color: white;
  font-weight: 600;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;

  ${({ theme }) => `color: ${theme === 'dark' ? 'white' : COLORS.DARK}`}
`

const HEADER_HEIGHT = 8

const StyledHeader = styled(Header)`
  background-color: ${COLORS.DARK};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}vh;
`

const ContentContainer = styled.div`
  background: white;
  padding: 16px;
`

const StyledContent = styled(Content)`
  padding: 0 0px;
  height: ${100 - HEADER_HEIGHT}vh;
  overflow: scroll;
`

const MenuButton = styled(Button)`
  @media (min-width: 992px) {
    display: none;
  }
`

const StyledLogo = styled('img')`
  display: none;

  @media only screen and (min-width: 768px) {
    height: 50%;
    display: flex;
  }
`

const StyledDivider = styled(Divider)`
  @media (min-width: 992px) {
    background-color: #e8e8e8;
    margin: 4px 0;
  }
`

const StyledLayout = styled(Layout)``

const AppMenu = ({ theme }) => {
  const { logout, currentUser } = useAuth()
  const navigate = useNavigate()
  const { userData, loading } = useUser()
  const location = useLocation()

  const { data: jobs } = useJobs(
    isAdmin(userData)
      ? null
      : isGraphic(userData)
      ? where('graphicUid', '==', currentUser.uid)
      : where('authorUid', '==', currentUser.uid)
  )

  const selectedKey = location?.pathname?.split('/')?.[1]

  return (
    <>
      <UserNameContainer theme={theme}>
        <Avatar
          icon={userData?.avatar !== undefined ? '' : <UserOutlined />}
          src={userData?.avatar !== undefined ? userData?.avatar : ''}
        />
        {currentUser.email}
      </UserNameContainer>
      <StyledDivider />
      <Menu theme={theme} selectedKeys={[selectedKey]}>
        <Menu.Item
          key="account"
          onClick={() => {
            navigate(ROUTES.ACCOUNT)
          }}
        >
          <Translate name="account" />
        </Menu.Item>

        <Menu.Item
          key="jobs"
          onClick={() => {
            navigate(ROUTES.JOBS)
          }}
        >
          <Translate name="jobs" />
          <Badge
            size="small"
            count={
              jobs.filter(
                ({ status }) =>
                  status !== JOB_STATUSES.CANCELLED &&
                  status !== JOB_STATUSES.CLOSED
              ).length
            }
            offset={[10, -10]}
          />
        </Menu.Item>

        {!loading && isAdmin(userData) && (
          <Menu.Item
            key="users"
            onClick={() => {
              navigate(ROUTES.USERS)
            }}
          >
            <Translate name="users" />
          </Menu.Item>
        )}

        <Menu.Item key="logout" onClick={() => logout()}>
          <Translate name="logout" />
        </Menu.Item>
      </Menu>
    </>
  )
}

const PrivatePage = ({ header, children, headerEndContent }) => {
  const [visible, setVisible] = useState(false)

  return (
    <Layout>
      <SideBar menu={<AppMenu theme="dark" />} />
      <StyledLayout>
        <StyledHeader>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <MenuButton
              type="primary"
              icon={<MenuOutlined />}
              onClick={() => setVisible(true)}
            />
            <Drawer
              placement="left"
              closable={false}
              onClick={() => setVisible(false)}
              onClose={() => setVisible(false)}
              visible={visible}
            >
              <CenteredFlex>
                <img alt="logo" src={blueLogo} style={{ width: '60%' }} />
              </CenteredFlex>
              <Divider />
              <AppMenu theme="light" />
            </Drawer>
            <StyledTitle level={2}>{header}</StyledTitle>
          </div>
          <StyledLogo alt="logo" src={whiteLogo} />
          <div>{headerEndContent}</div>
        </StyledHeader>
        <StyledContent>
          <ContentContainer>{children}</ContentContainer>
        </StyledContent>
        <StyledFooter>
          <Translate name="footer" />
        </StyledFooter>
      </StyledLayout>
    </Layout>
  )
}

export default PrivatePage
