import { Form, Input, Button, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { FormContainer } from '../components/SharedComponents'
import PublicPage from '../components/Layout/PublicPage'
import '../translations/i18n'
import { useAuth } from '../contexts/AuthContext'
import Translate from '../components/Translate'

const { Title } = Typography

const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  const { forgotPassword } = useAuth()
  const onFinish = ({ email }) => {
    forgotPassword(email)
  }

  return (
    <PublicPage>
      <FormContainer>
        <Title level={2}>
          <Translate name="forgotPassword" />
        </Title>
        <Form name="forgotPassword" onFinish={onFinish}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: t('invalidEmail'),
              },
              {
                required: true,
                message: t('missingEmail'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <Translate name="sendForgotPasswordMail" />
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
    </PublicPage>
  )
}

export default ForgotPasswordPage
