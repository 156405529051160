import i18next from 'i18next'
import { notifyError } from './notifications'

const handleError = ({ error, message }) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Error occured', error)
  }
  notifyError({
    message: i18next.t('errorMessages.title'),
    description: i18next.t(`errorMessages.${message}`),
  })
  // TODO: send it somewhere
}

// eslint-disable-next-line import/prefer-default-export
export { handleError }
