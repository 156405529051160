import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { LeftFormContainer } from '../components/SharedComponents'
import '../translations/i18n'
import PrivatePage from '../components/Layout/PrivatePage'
import { useAuth } from '../contexts/AuthContext'
import Translate from '../components/Translate'
import { notifySuccess } from '../utils/notifications'
import { handleError } from '../utils/logger'

const ChangePasswordPage = () => {
  const { t } = useTranslation()
  const { changePassword } = useAuth()

  const onFinish = async (values) => {
    try {
      await changePassword(values.oldPassword, values.password)
      notifySuccess({ message: 'done' })
    } catch (error) {
      handleError({ error })
    }
  }

  return (
    <PrivatePage header={t('changePasswordPage.title')}>
      <LeftFormContainer>
        <Form name="changePassword" onFinish={onFinish}>
          <Form.Item
            name="oldPassword"
            label={t('changePasswordPage.oldPassowrd')}
            rules={[
              {
                required: true,
                message: t('passwordMissing'),
              },
              () => ({
                validator(_, value) {
                  if (value.length < 6)
                    return Promise.reject(new Error(t('passwordMinLength')))
                  return Promise.resolve()
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('password')}
            rules={[
              {
                required: true,
                message: t('passwordMissing'),
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={t('confirmPassword')}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: t('confirmPassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('passwordsMustMatch')))
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              <Translate name="changePasswordPage.change" />
            </Button>
          </Form.Item>
        </Form>
      </LeftFormContainer>
    </PrivatePage>
  )
}

export default ChangePasswordPage
