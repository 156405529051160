import { useTranslation } from 'react-i18next'
import { Button, Form, Avatar, Typography, Select } from 'antd'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { notifySuccess } from '../utils/notifications'
import { LeftFormContainer } from '../components/SharedComponents'
import '../translations/i18n'
import PrivatePage from '../components/Layout/PrivatePage'
import { useSetAccount, useAccount } from '../hooks/dataLayer'
import Translate from '../components/Translate'
import { FormCheckboxInput, FormTextInput } from '../components/FormInputs'
import LoadingContainer from '../components/LoadingContainer'

const { Title } = Typography

const AvatarContainer = styled.div`
  padding: 0 0 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const UserPage = () => {
  const { t } = useTranslation()
  const [accountData, setAccountData] = useState({})
  const [form] = Form.useForm()

  const params = useParams()
  const { id } = params

  const { data, loading, error } = useAccount(id)
  const { save, loading: isSaving } = useSetAccount(id)

  useEffect(() => {
    if (data) {
      setAccountData(data)
      form.setFieldsValue(data)
    }
  }, [data])

  const onFinish = async () => {
    await save(accountData)
    notifySuccess({ message: t('successMessages.saved') })
  }

  return (
    <PrivatePage header={t('user')}>
      <LoadingContainer loading={loading} error={error}>
        <LeftFormContainer>
          <AvatarContainer>
            <Avatar
              size={64}
              icon={accountData?.avatar !== undefined ? '' : <UserOutlined />}
              src={accountData?.avatar !== undefined ? accountData?.avatar : ''}
            />
          </AvatarContainer>
          <Form
            form={form}
            name="account"
            onFinish={onFinish}
            onValuesChange={(_, values) =>
              setAccountData({ ...accountData, ...values })
            }
          >
            <FormTextInput name="firstName" disabled />
            <FormTextInput name="lastName" disabled />
            <FormTextInput name="portfolioURL" disabled />

            <Form.Item
              label={t('userType')}
              name="userType"
              rules={[
                {
                  required: true,
                  message: t('requiredValue'),
                },
              ]}
            >
              <Select style={{ width: 120 }}>
                <Select.Option value="customer">
                  <Translate name="accountPage.customer" />
                </Select.Option>
                <Select.Option value="graphic">
                  <Translate name="accountPage.graphic" />
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Title level={5}>
                <Translate name="address" />
              </Title>
            </Form.Item>

            <FormCheckboxInput name="isCompany" disabled />

            <FormTextInput name="companyName" disabled />
            <FormTextInput name="companyID1" disabled />
            <FormTextInput name="companyID2" disabled />
            <FormTextInput name="companyID3" disabled />

            <FormTextInput name="addressLine1" disabled />
            <FormTextInput name="addressLine2" disabled />

            <FormTextInput name="zipCode" disabled />
            <FormTextInput name="city" disabled />
            <FormTextInput name="country" disabled />

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={isSaving}>
                {t('save')}
              </Button>
            </Form.Item>
          </Form>
        </LeftFormContainer>
      </LoadingContainer>
    </PrivatePage>
  )
}

export default UserPage
