import { JOB_EVENTS } from '../../utils/constants'

export default {
  username: 'Prihlasovacie meno',
  usernameMissing: 'Zadajte prihlasovacie meno',
  password: 'Heslo',
  passwordMissing: 'Zadajte heslo',
  forgotPassword: 'Zabudnuté heslo',
  login: 'Prihlásenie',
  or: 'Alebo ',
  toRegister: 'sa zaregistrujte!',
  loginWithGoogle: 'Prihlásiť sa s Google účtom',
  logout: 'Odhlásiť',
  footer: 'Minútový Grafik',
  register: 'Zaregistrovať',
  registration: 'Registrácia',
  passwordsMustMatch: 'Heslá sa musia zhodovať',
  sendForgotPasswordMail: 'Resetovať heslo',
  confirmPassword: 'Zopakujte heslo',
  userRegistrationSuccessTitle: 'Úspešná registrácia',
  passwordResetSuccessTitle: 'Heslo sa úspešne resetovalo',
  saved: 'Uložené',
  save: 'Uložiť',
  account: 'Účet',
  jobs: 'Práce',
  newJob: 'Vytvoriť',
  title: 'Nadpis',
  description: 'Popis',
  firstName: 'Priezvisko',
  lastName: 'Meno',
  portfolioURL: 'Link na portfólio',
  isCompany: 'Právnická osoba',
  companyName: 'Názov firmy',
  companyID1: 'IČO',
  companyID2: 'DIČ',
  companyID3: 'IČ DPH',
  requiredValue: 'Pole je povinné',
  read: 'Prečítal/a som si ',
  agreemet: 'obchodné podmienky',
  loading: 'Načítavam...',
  uploadImage: 'Nahrať obraz',
  addressLine1: 'Adresa',
  addressLine2: 'Adresa',
  zpiCode: 'PSČ',
  city: 'Mesto',
  country: 'Štát',
  address: 'Adresa',
  userType: 'Typ používateľa',
  passwordMinLength: 'Heslo musí obsahovať minimálne 6 znakov',
  resetPassword: 'Resetovanie hesla',
  accountPage: {
    customer: 'Zákazník',
    graphic: 'Grafik',
  },
  yes: 'Ano',
  no: 'Nie',
  errorMessages: {
    title: 'Chyba',
    fetchError: 'Nepodarilo sa načítať dáta',
    uploadError: 'Nepodarilo sa nahrať súbor',
    saveError: 'Nepodarilo sa uložiť údaje',
    login: 'Prihlasovacie údaje sú neplatné',
    changePassword: 'Nepodarilo sa zmeniť heslo',
    deleteFileError: 'Nepodarilo sa vymazať súbor',
  },
  successMessages: {
    upload: 'Súbor sa nahral',
    saved: 'Údaje sa uložili',
  },
  warningMessages: {
    accountSetup: 'Vyplnte profilové údaje',
    fileUploadBadFormat: 'Podporovaný formát je JPG alebo PNG',
    fileUploadTooBig: 'Obraz nemôže presahovať velkosť 2MB',
  },
  shared: {
    loading: 'Načítavam..',
    error: 'Oops. Chyba. Načítajte stránku znova',
    intlDateTime: '{{val, datetime}}',
    detail: 'Detail',
    save: 'Uložiť',
    create: 'Vytvoriť',
    searchPlaceholder: 'Vyhladávanie',
    copy: 'Kópia',
  },
  jobPage: {
    files: 'Súbory',
    timeline: 'Časová os',
    title: 'Detail práce',
    download: 'Stiahnúť',
    uploadText: 'Kliknutím ale potiahnutím sem nahrajte súbor',
    timer: 'Časovač',
    startTimer: 'Spustiť časovať',
    stopTimer: 'Zastaviť časovač',
    timerNotStarted: 'Časovač ešte nebol spustený',
    uploading: 'Nahrávanie beží',
    makePublic: 'Sprístupniť',
    actions: 'Akcie',
    cancelJob: 'Vymazať prácu',
    duplicateJob: 'Duplikovať',
    appointment: 'Stretnutie',
    date: 'Dátum',
    time: 'Čas',
    reservate: 'Rezervovať',
    selectTime: 'Vyberte čas',
    selectDate: 'Vyberte dátum',
    graphicProfile: 'Profil grafika',
    appointmentReady: 'Dátum a čas schôdze:',
    appointmentCreated: 'Vytvorenie schôdzy',
    customerProfile: 'Profil zadávateľa',
    closeJob: 'Uzatvorenie práce',
    noAvailableGraphic: 'Grafik nie je dostupný',
    events: {
      [JOB_EVENTS.APPOINTMENT_CREATION]: 'Rerzervácia termínu',
      [JOB_EVENTS.CANCEL]: 'Zrušenie',
      [JOB_EVENTS.CLOSE]: 'Uzatvorenie',
      [JOB_EVENTS.CREATE]: 'Vytvorenie',
      [JOB_EVENTS.EDIT]: 'Zmena',
      [JOB_EVENTS.FILE_DELETE]: 'Vymazaný súbor',
      [JOB_EVENTS.FILE_UPLOAD]: 'Nahranie súboru',
      [JOB_EVENTS.TIMER_START]: 'Časovač spustený',
      [JOB_EVENTS.TIMER_STOP]: 'Časovač zastavený',
      [JOB_EVENTS.FINISH]: 'Dokončenie',
    },
    clientFiles: 'Súbory od zadávateľa',
    graphicFiles: 'Súbory od grafika',
    yourFiles: 'Vaše súbory',
    deleteReask:
      'Týmto krokom vymažete všetky súbory. Ste si istý, že ich chcete nenávratne vymazať?',
  },
  changePasswordPage: {
    title: 'Zmena hesla',
    oldPassowrd: 'Staré heslo',
    change: 'Zmeniť heslo',
  },
  profilePage: {
    title: 'Profil',
    pastJobs: 'História prác',
    basicInfo: 'Základné informácie',
  },
  jobStatuses: {
    draft: 'Rozpracovaná',
    ready: 'Pripravená',
    inprogress: 'Prebieha',
    cancelled: 'Zrušená',
    closed: 'Uzatvorená',
  },
  users: 'Používatelia',
}
