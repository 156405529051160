import { doc, getDoc } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { NEW_ENTITY_ID } from '../utils/constants'
import { db } from '../utils/firebase'
import { handleError } from '../utils/logger'

const useFetchFirebase = (coll, id, defaultValue = null) => {
  const [data, setData] = useState(defaultValue)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const loadData = async (fetchId = id) => {
    try {
      if (fetchId === NEW_ENTITY_ID) {
        setData({})
      } else {
        setLoading(true)
        const docSnap = await getDoc(doc(db, coll, fetchId))
        setData(docSnap.data())
      }
    } catch (err) {
      handleError({ error: err, message: 'fetchError' })
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id !== null) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [id])

  return { data, loading, error, refetch: loadData }
}

export default useFetchFirebase
