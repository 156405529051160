import { useState } from 'react'
import { Button, Divider, List, Upload } from 'antd'
import {
  InboxOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import {
  useDeleteJobFile,
  useJobFiles,
  useSetJobFile,
  useSetJobLog,
} from '../../hooks/dataLayer'
import useFileUpload from '../../hooks/useFileUpload'
import Translate from '../../components/Translate'
import { useUser } from '../../contexts/UserContext'
import { isCustomer, isGraphic } from '../../utils/helper'
import { useAuth } from '../../contexts/AuthContext'
import { handleError } from '../../utils/logger'
import { JOB_EVENTS, USER_TYPES } from '../../utils/constants'
import { deleteFile } from '../../utils/storage'
import EnsureRole from '../../components/EnsureRole'

const File = ({ jobId, file }) => {
  const { userData } = useUser()
  const { currentUser } = useAuth()
  const { id, url, name, isPublic, authorUid, fullName } = file
  const { save: saveJobLog } = useSetJobLog(jobId)

  const { save, loading } = useSetJobFile(jobId, id)
  const { deleteData, loading: deleting } = useDeleteJobFile(jobId, id)

  const actions = [
    <a href={url} download>
      <Translate name="jobPage.download" />
    </a>,
  ]

  if (currentUser.uid === authorUid) {
    if (isGraphic(userData) && isPublic)
      actions.push(
        <Button
          icon={<LockOutlined />}
          onClick={async () => {
            await save({ ...file, isPublic: false })
          }}
          loading={loading || deleting}
        />
      )
    if (isGraphic(userData) && !isPublic)
      actions.push(
        <Button
          icon={<UnlockOutlined />}
          onClick={async () => {
            await save({ ...file, isPublic: true })
          }}
          loading={loading || deleting}
        >
          <Translate name="jobPage.makePublic" />
        </Button>
      )
    actions.push(
      <Button
        danger
        icon={<DeleteOutlined />}
        onClick={async () => {
          await deleteData()
          await deleteFile(fullName)
          await saveJobLog({
            event: JOB_EVENTS.FILE_DELETE,
            data: { fileName: name, fileUid: id },
          })
        }}
        loading={loading || deleting}
      />
    )
  }

  return (
    <List.Item actions={actions}>
      <List.Item.Meta title={name} />
    </List.Item>
  )
}

const Files = ({ jobId, job }) => {
  const { userData } = useUser()
  const { currentUser } = useAuth()
  const { data: fileList } = useJobFiles(jobId)
  const { save } = useSetJobFile(jobId)
  const { save: saveJobLog } = useSetJobLog(jobId)
  const { upload } = useFileUpload()
  const [uploadingCount, setuploadingCount] = useState(0)

  const saveJobAndAddLog = async (fileData) => {
    await save({ ...fileData, isPublic: isCustomer(userData) })
    await saveJobLog({
      event: JOB_EVENTS.FILE_UPLOAD,
      data: { fileName: fileData.name, fileUid: fileData.uid },
    })
  }

  return (
    <div>
      <Upload.Dragger
        showUploadList={false}
        multiple
        customRequest={async ({ file }) => {
          try {
            setuploadingCount((count) => count + 1)
            const fileData = await upload(file, jobId)
            await saveJobAndAddLog(fileData)
          } catch (e) {
            handleError({ error: e })
          } finally {
            setuploadingCount((count) => count - 1)
          }
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p>
          <Translate name="jobPage.uploadText" />
        </p>
      </Upload.Dragger>

      {uploadingCount > 0 && <Translate name="jobPage.uploading" />}
      {fileList.some(({ authorUid }) => authorUid !== currentUser.uid) && (
        <>
          <List
            header={
              <>
                <EnsureRole role={USER_TYPES.CUSTOMER}>
                  <Translate name="jobPage.graphicFiles" />
                </EnsureRole>
                <EnsureRole role={USER_TYPES.GRAPHIC}>
                  <Translate name="jobPage.clientFiles" />
                </EnsureRole>
              </>
            }
            dataSource={fileList.filter(
              ({ isPublic, authorUid }) =>
                authorUid !== currentUser.uid &&
                (isPublic !== false || !isCustomer(userData))
            )}
            renderItem={(item) => <File file={item} jobId={jobId} job={job} />}
          />
          <Divider />
        </>
      )}
      {fileList.some(({ authorUid }) => authorUid === currentUser.uid) && (
        <List
          header={<Translate name="jobPage.yourFiles" />}
          dataSource={fileList.filter(
            ({ isPublic, authorUid }) =>
              authorUid === currentUser.uid &&
              (isPublic !== false || !isCustomer(userData))
          )}
          renderItem={(item) => <File file={item} jobId={jobId} job={job} />}
        />
      )}
    </div>
  )
}

export default Files
