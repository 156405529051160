import { useTranslation } from 'react-i18next'
import { where } from 'firebase/firestore'
import { Divider, List } from 'antd'
import { useParams } from 'react-router-dom'
import '../translations/i18n'
import PrivatePage from '../components/Layout/PrivatePage'
import { useAccount, useJobs } from '../hooks/dataLayer'
import LoadingContainer from '../components/LoadingContainer'
import { isGraphic } from '../utils/helper'
import TitleTranslate from '../components/TitleTranslate'

const ProfilePageJobsList = ({ userData, id }) => {
  const { data: jobs } = useJobs(
    isGraphic(userData)
      ? where('graphicUid', '==', id)
      : where('authorUid', '==', id)
  )

  return (
    <List
      itemLayout="horizontal"
      dataSource={jobs.slice(0, 5)}
      bordered
      size="small"
      renderItem={({ title, description, id: jobId }) => (
        <List.Item key={jobId}>
          <List.Item.Meta title={title} description={description} />
        </List.Item>
      )}
    />
  )
}

const ProfilePage = () => {
  const { t } = useTranslation()

  const params = useParams()
  const { id } = params

  const { data: userData, loading, error } = useAccount(id)

  return (
    <PrivatePage header={t('profilePage.title')}>
      <LoadingContainer loading={loading} error={error}>
        <TitleTranslate level={4} name="profilePage.basicInfo" />
        <span>{userData.firstName} </span>
        <span>{userData.lastName}</span>
        <Divider />
        <TitleTranslate level={4} name="profilePage.pastJobs" />
        <ProfilePageJobsList userData={userData} id={id} />
      </LoadingContainer>
    </PrivatePage>
  )
}

export default ProfilePage
