import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'

const StyledSider = styled(Layout.Sider)`
  height: 100vh;
`

const SideBar = ({ menu }) => {
  return (
    <StyledSider breakpoint="lg" theme="dark" collapsedWidth={0} trigger={null}>
      {menu}
    </StyledSider>
  )
}
export default SideBar
