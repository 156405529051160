import React, { useEffect, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import i18next from 'i18next'
import { useAuth } from '../contexts/AuthContext'
import ForgotPasswordPage from '../pages/ForgotPassword'
import LoginPage from '../pages/LoginPage'
import ResetPasswordPage from '../pages/ResetPassword'
import AccountPage from '../pages/AccountPage'
import RegistrationPage from '../pages/Registration'
import JobsPage from '../pages/JobsPage'
import JobPage from '../pages/JobPage'
import { ROUTES } from '../utils/constants'
import { notifyWarning } from '../utils/notifications'
import { useUser } from '../contexts/UserContext'
import SplashScreenLoader from './SplashScreen'
import { isAdmin } from '../utils/helper'
import UsersPage from '../pages/Users'
import UserPage from '../pages/User'
import ChangePasswordPage from '../pages/ChangePassword'
import ProfilePage from '../pages/ProfilePage'

const AccountSetupRedirect = () => {
  useEffect(
    () => notifyWarning({ message: i18next.t('warningMessages.accountSetup') }),
    []
  )

  return <Navigate to={ROUTES.ACCOUNT} replace />
}

const AdminRoute = ({ children }) => {
  const { userData } = useUser()
  if (!isAdmin(userData)) {
    return <Navigate to={ROUTES.ACCOUNT} replace />
  }

  return children
}

const isAccountSetup = (userData) => userData?.zipCode && userData?.city

const AccountSetupRoute = ({ children }) => {
  const { userData } = useUser()
  if (!isAccountSetup(userData)) {
    return <AccountSetupRedirect />
  }

  return children
}

const AppRouter = () => {
  const { currentUser, loading: userAuthenticating } = useAuth()
  const { loading: userDataLoading } = useUser()

  return (
    <Router>
      {userAuthenticating || userDataLoading ? (
        <SplashScreenLoader />
      ) : (
        <Suspense fallback={<SplashScreenLoader />}>
          <Routes>
            {currentUser ? (
              <>
                <Route path={ROUTES.ACCOUNT} element={<AccountPage />} />
                <Route
                  path={ROUTES.CHANGE_PASSWORD}
                  element={<ChangePasswordPage />}
                />
                <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
                <Route path="*" element={<Navigate to={ROUTES.JOBS} />} />
                <Route
                  path={ROUTES.USERS}
                  element={
                    <AdminRoute>
                      <UsersPage />
                    </AdminRoute>
                  }
                />
                <Route
                  path={ROUTES.USER}
                  element={
                    <AdminRoute>
                      <UserPage />
                    </AdminRoute>
                  }
                />
                <Route
                  path={ROUTES.ACCOUNT}
                  element={
                    <AccountSetupRoute>
                      <AccountPage />
                    </AccountSetupRoute>
                  }
                />
                <Route
                  path={ROUTES.JOBS}
                  element={
                    <AccountSetupRoute>
                      <JobsPage />
                    </AccountSetupRoute>
                  }
                />
                <Route
                  path={ROUTES.JOB}
                  element={
                    <AccountSetupRoute>
                      <JobPage />
                    </AccountSetupRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <AccountSetupRoute>
                      <JobsPage />
                    </AccountSetupRoute>
                  }
                />
              </>
            ) : (
              <>
                <Route
                  path="/"
                  element={<Navigate to={ROUTES.LOGIN} replace />}
                />
                <Route path={ROUTES.LOGIN} element={<LoginPage />} />
                <Route
                  path={ROUTES.REGISTRATION}
                  element={<RegistrationPage />}
                />
                <Route
                  path={ROUTES.FORGOT_PASSWORD}
                  element={<ForgotPasswordPage />}
                />
                <Route
                  path={ROUTES.RESET_PASSSWORD}
                  element={<ResetPasswordPage />}
                />
                <Route path="*" element={<LoginPage />} />
              </>
            )}
          </Routes>
        </Suspense>
      )}
    </Router>
  )
}

export default AppRouter
