import { useEffect, useState } from 'react'
import { Button, Typography } from 'antd'
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons'
import { Timestamp } from 'firebase/firestore'
import { useSetJob, useSetJobLog } from '../../hooks/dataLayer'
import Translate from '../../components/Translate'
import EnsureRole from '../../components/EnsureRole'
import { getTime } from '../../utils/helper'
import { JOB_EVENTS, JOB_STATUSES, USER_TYPES } from '../../utils/constants'

const DEFAULT_VALUE = ''

const calculateTimeLeft = (from, to) => {
  if (!from || !to) return 0
  return Math.floor(to.seconds - from.seconds)
}

const TimerController = ({ id, job }) => {
  const { save } = useSetJob(id)
  const { save: saveNewLog } = useSetJobLog(id)
  const [calculateTimerFrom, setCalculateTimerFrom] = useState(null)
  const [timer, setTimer] = useState(
    calculateTimeLeft(calculateTimerFrom, Timestamp.now())
  )
  const isTimerRunning = job.timerStartedAt
  const isTimerStartable =
    job.status === JOB_STATUSES.READY || job.status === JOB_STATUSES.INPROGRESS

  useEffect(() => {
    setCalculateTimerFrom(job.timerStartedAt)
  }, [job.timerStartedAt])

  useEffect(() => {
    const t = setTimeout(() => {
      setTimer(
        calculateTimeLeft(calculateTimerFrom, Timestamp.now()) +
          (job.counter || 0)
      )
    }, 1000)

    return () => clearTimeout(t)
  })

  const startTimer = async () => {
    await save({
      ...job,
      timerStartedAt: Timestamp.now(),
      status: JOB_STATUSES.INPROGRESS,
    })
    await saveNewLog({ event: JOB_EVENTS.TIMER_START })
  }

  const stopTimer = async () => {
    await save({
      ...job,
      timerStartedAt: DEFAULT_VALUE,
      counter: timer,
    })
    await saveNewLog({ event: JOB_EVENTS.TIMER_STOP })
  }

  return (
    <>
      {!isTimerRunning && isTimerStartable && (
        <EnsureRole role={USER_TYPES.GRAPHIC}>
          <Button
            type="primary"
            icon={<CaretRightOutlined />}
            onClick={async () => {
              await startTimer()
            }}
          >
            <Translate name="jobPage.startTimer" />
          </Button>
        </EnsureRole>
      )}

      {isTimerRunning && (
        <EnsureRole role={USER_TYPES.GRAPHIC}>
          <Button
            type="primary"
            icon={<PauseOutlined />}
            onClick={async () => {
              await stopTimer()
            }}
          >
            <Translate name="jobPage.stopTimer" />
          </Button>
        </EnsureRole>
      )}

      {timer > 0 && (
        <Typography.Title level={5}>
          {getTime(new Date(timer * 1000))}
        </Typography.Title>
      )}

      {timer === 0 && (
        <EnsureRole role={USER_TYPES.CUSTOMER}>
          <Translate name="jobPage.timerNotStarted" />
        </EnsureRole>
      )}
    </>
  )
}

export default TimerController
