import { doc, onSnapshot } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { db } from '../utils/firebase'
import { handleError } from '../utils/logger'

const useSingleSnapshotFirebase = (coll, id) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const loadData = async () => {
    try {
      setLoading(true)
      setError(null)
      onSnapshot(doc(db, coll, id), (d) => {
        setData(d.data())
      })
    } catch (err) {
      handleError({ error: err, message: 'fetchError' })
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id !== null && id !== undefined) {
      loadData()
    }
  }, [id])

  return { data, loading, error }
}

export default useSingleSnapshotFirebase
