import { useEffect } from 'react'
import { Col, Divider, Form, Input, Row } from 'antd'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'
import TitleTranslate from '../../components/TitleTranslate'
import JobTimeline from './JobTimeline'
import { LeftFormContainer } from '../../components/SharedComponents'
import Files from './Files'
import TimerController from './TimerController'
import Actions from './Actions'
import Appointment from './Appointment'

const { useForm } = Form

const GraphicView = ({ id, job }) => {
  const [form] = useForm()
  const { t } = useTranslation()

  useEffect(() => {
    form.setFieldsValue(job)
  }, [job])

  return (
    <Row gutter={[24, 16]}>
      <Col lg={{ span: 12 }} md={{ span: 24 }}>
        <LeftFormContainer>
          <TitleTranslate name="shared.detail" level={4} />
          <Form form={form} name="job">
            <Form.Item label={t('title')} name="title">
              <Input placeholder={t('title')} disabled />
            </Form.Item>

            <Form.Item label={t('description')} name="description">
              <Input.TextArea placeholder={t('description')} disabled />
            </Form.Item>
          </Form>
        </LeftFormContainer>
        <Divider />
        <Appointment id={id} job={job} />
        <Divider />
        <TitleTranslate name="jobPage.files" level={4} />
        <Files jobId={id} job={job} />
      </Col>
      <Col lg={{ span: 12 }} md={{ span: 24 }}>
        <Actions id={id} job={job} />
        <TitleTranslate level={4} name="jobPage.timer" />
        <TimerController id={id} job={job} />
        <Divider />
        <TitleTranslate level={4} name="jobPage.timeline" />
        <JobTimeline jobId={id} />
      </Col>
    </Row>
  )
}

export default GraphicView
