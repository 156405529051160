import { List } from 'antd'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PrivatePage from '../components/Layout/PrivatePage'
import { ROUTES } from '../utils/constants'
import '../translations/i18n'
import { useUsers } from '../hooks/dataLayer'

const UsersPage = () => {
  const { data: users } = useUsers()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <PrivatePage header={t('users')}>
      <List
        itemLayout="horizontal"
        dataSource={users}
        bordered
        size="small"
        renderItem={({ id, authorEmail, userType, firstName, lastName }) => (
          <List.Item
            key={id}
            onClick={() => navigate(generatePath(ROUTES.USER, { id }))}
          >
            <List.Item.Meta
              title={`${firstName} ${lastName} - ${authorEmail}`}
              description={userType || 'Customer'}
            />
          </List.Item>
        )}
      />
    </PrivatePage>
  )
}

export default UsersPage
