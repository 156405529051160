import { Button, Divider, Popconfirm } from 'antd'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  useJobFiles,
  useSetJob,
  useSetJobFile,
  useSetJobLog,
} from '../../hooks/dataLayer'
import TitleTranslate from '../../components/TitleTranslate'
import Translate from '../../components/Translate'
import {
  DB_COLLECTIONS,
  JOB_EVENTS,
  JOB_STATUSES,
  NEW_ENTITY_ID,
  ROUTES,
  USER_TYPES,
} from '../../utils/constants'
import EnsureRole from '../../components/EnsureRole'
import { deleteFile } from '../../utils/storage'

const Actions = ({ id, job }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { status } = job
  const { save, loading: isSaving } = useSetJob(id)
  const { data: files } = useJobFiles(id)

  const { save: createJob, loading: isCreating } = useSetJob(NEW_ENTITY_ID)
  const { save: addFileToJob } = useSetJobFile(NEW_ENTITY_ID, undefined)

  const { save: saveNewLog } = useSetJobLog(id)

  const setJobStatus = async (jobStatus, event) => {
    await save({
      ...job,
      status: jobStatus,
    })
    await saveNewLog({ event })
  }

  const duplicateJob = async () => {
    const { id: newJobId } = await createJob({
      ...job,
      title: `${job.title} - ${t('shared.copy')}`,
      status: JOB_STATUSES.DRAFT,
      timer: null,
      timerStartedAt: null,
      counter: null,
      graphicUid: null,
      time: null,
      date: null,
    })
    await saveNewLog(
      { event: JOB_EVENTS.CREATE },
      generatePath(DB_COLLECTIONS.JOBLOGS, { id: newJobId })
    )

    await Promise.all(
      files.map((file) =>
        addFileToJob(
          file,
          generatePath(DB_COLLECTIONS.JOBFILES, { id: newJobId }),
          undefined
        )
      )
    )

    navigate(ROUTES.JOBS)
  }

  return (
    <>
      <TitleTranslate level={4} name="jobPage.actions" />
      <EnsureRole role={USER_TYPES.GRAPHIC}>
        {status === JOB_STATUSES.INPROGRESS && (
          <Button
            onClick={() => setJobStatus(JOB_STATUSES.CLOSED, JOB_EVENTS.CLOSE)}
            loading={isSaving}
          >
            <Translate name="jobPage.closeJob" />
          </Button>
        )}
      </EnsureRole>
      <EnsureRole role={USER_TYPES.CUSTOMER}>
        <Popconfirm
          title={<Translate name="jobPage.deleteReask" />}
          okText={<Translate name="yes" />}
          cancelText={<Translate name="no" />}
          onConfirm={async () => {
            await setJobStatus(JOB_STATUSES.CANCELLED, JOB_EVENTS.CANCEL)
            await Promise.all(files.map((file) => deleteFile(file.fullName)))
            navigate(ROUTES.JOBS)
          }}
        >
          <Button danger loading={isSaving}>
            <Translate name="jobPage.cancelJob" />
          </Button>
        </Popconfirm>

        {status === JOB_STATUSES.CLOSED && (
          <Button
            onClick={async () => {
              await duplicateJob()
            }}
            loading={isCreating}
          >
            <Translate name="jobPage.duplicateJob" />
          </Button>
        )}
      </EnsureRole>
      <Divider />
    </>
  )
}

export default Actions
