import { Form, Input, Switch } from 'antd'
import { useTranslation } from 'react-i18next'
import '../translations/i18n'

const FormTextInput = ({ name, required, disabled }) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      label={t(name)}
      name={name}
      rules={
        required
          ? [
              {
                required: true,
                message: t('requiredValue'),
              },
            ]
          : []
      }
    >
      <Input placeholder={t(name)} disabled={disabled} />
    </Form.Item>
  )
}

const FormCheckboxInput = ({ name, disabled }) => {
  const { t } = useTranslation()
  return (
    <Form.Item label={t(name)} name={name} valuePropName="checked">
      <Switch disabled={disabled} />
    </Form.Item>
  )
}

export { FormTextInput, FormCheckboxInput }
