import { doc, deleteDoc } from 'firebase/firestore'
import { useState } from 'react'
import { db } from '../utils/firebase'
import { handleError } from '../utils/logger'

const useDeleteFirebase = (coll, id) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const deleteData = async () => {
    try {
      setLoading(true)
      setError(null)
      await deleteDoc(doc(db, coll, id))
    } catch (err) {
      handleError({ error: err, message: 'deleteError' })
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, deleteData }
}

export default useDeleteFirebase
