import {
  addDoc,
  doc,
  setDoc,
  collection as fbcollection,
  Timestamp,
} from 'firebase/firestore'
import { useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { NEW_ENTITY_ID } from '../utils/constants'
import { db } from '../utils/firebase'
import { removeEmptyProperties } from '../utils/helper'
import { handleError } from '../utils/logger'

const useSaveFirebase = (coll, id) => {
  const { currentUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const save = async (values, collection = coll, saveId = id) => {
    try {
      setLoading(true)
      setError(null)
      let docRef

      if (saveId === NEW_ENTITY_ID || saveId === undefined) {
        docRef = await addDoc(fbcollection(db, collection), {
          ...removeEmptyProperties(values),
          created: Timestamp.now(),
          changed: Timestamp.now(),
          authorUid: currentUser.uid,
          authorEmail: currentUser.email,
        })
        return { id: docRef?.id || id || saveId }
      }
      await setDoc(doc(db, collection, saveId), {
        authorUid: currentUser.uid,
        created: Timestamp.now(),
        ...removeEmptyProperties(values),
        changed: Timestamp.now(),
        changeUid: currentUser.uid,
        changeEmail: currentUser.email,
      })
      return { id: id || saveId }
    } catch (err) {
      handleError({ error: err, message: 'saveError' })
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { save, loading, error }
}

export default useSaveFirebase
