import { Space } from 'antd'
import TitleTranslate from '../../components/TitleTranslate'
import Translate from '../../components/Translate'
import CreateAppointment from './CreateAppointment'

const Appointment = ({ id, job }) => {
  const { graphicUid, time, date } = job
  const isAppointmentSet = !!graphicUid

  return (
    <>
      <TitleTranslate level={4} name="jobPage.appointment" />
      {isAppointmentSet ? (
        <Space direction="vertical">
          <Space>
            <Translate name="jobPage.appointmentReady" />
            <Translate
              name="shared.intlDateTime"
              val={{
                val: new Date(`${date} ${time}:00Z`),
                formatParams: {
                  val: {
                    minute: 'numeric',
                    hour: 'numeric',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  },
                },
              }}
            />
          </Space>
          {/* Temporary hide */}
          {/* <EnsureRole role={USER_TYPES.CUSTOMER}>
            <Button
              type="link"
              icon={<UserOutlined />}
              onClick={() =>
                navigate(generatePath(ROUTES.PROFILE, { id: graphicUid }))
              }
            >
              <Translate name="jobPage.graphicProfile" />
            </Button>
          </EnsureRole>
          <EnsureRole role={USER_TYPES.GRAPHIC}>
            <Button
              type="link"
              icon={<UserOutlined />}
              onClick={() =>
                navigate(generatePath(ROUTES.PROFILE, { id: authorUid }))
              }
            >
              <Translate name="jobPage.customerProfile" />
            </Button>
          </EnsureRole> */}
        </Space>
      ) : (
        <CreateAppointment id={id} job={job} />
      )}
    </>
  )
}
export default Appointment
