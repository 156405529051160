import { ref, deleteObject } from 'firebase/storage'
import { storage } from './firebase'
import { handleError } from './logger'

const deleteFile = async (file) => {
  try {
    const desertRef = ref(storage, file)

    await deleteObject(desertRef)
  } catch (err) {
    handleError({ error: err, message: 'deleteFileError' })
    throw err
  }
}

// eslint-disable-next-line import/prefer-default-export
export { deleteFile }
