import { t } from 'i18next'
import { USER_TYPES } from './constants'
import { notifyWarning } from './notifications'

const removeEmptyProperties = (obj) => {
  const clone = { ...obj }
  Object.keys(clone).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    if (clone[key] === null || clone[key] === undefined) delete clone[key]
  })
  return clone
}

const validateFileBeforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    notifyWarning({ message: t('warningMessages.fileUploadBadFormat') })
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    notifyWarning({ message: t('warningMessages.fileUploadTooBig') })
  }
  return isJpgOrPng && isLt2M
}

const convertUnixToDateTime = (seconds) => new Date(1000 * seconds)

const isAdmin = ({ userType }) => userType === USER_TYPES.ADMIN
const isGraphic = ({ userType }) => userType === USER_TYPES.GRAPHIC
const isCustomer = ({ userType }) =>
  !isAdmin({ userType }) && !isGraphic({ userType })

const getTime = (date) => date.toISOString().substring(11, 19)

export {
  removeEmptyProperties,
  validateFileBeforeUpload,
  convertUnixToDateTime,
  isAdmin,
  isGraphic,
  isCustomer,
  getTime,
}
