import { useState, useEffect } from 'react'
import { handleError } from '../utils/logger'

const useGraphicsAvailability = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const loadData = async () => {
    try {
      setLoading(true)
      setError(null)

      const response = await fetch(
        `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/getAvailableGraphics`
      )

      const responseData = await response.json()

      setData(responseData)
    } catch (err) {
      handleError({ error: err, message: 'fetchError' })
      setError(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return { data, loading, error, refetch: loadData }
}

export default useGraphicsAvailability
