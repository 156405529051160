import { Form, Input, Button, Checkbox, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { GoogleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import PublicPage from '../components/Layout/PublicPage'
import '../translations/i18n'
import { useAuth } from '../contexts/AuthContext'
import { FormContainer } from '../components/SharedComponents'
import { notifySuccess } from '../utils/notifications'
import { ROUTES } from '../utils/constants'
import Translate from '../components/Translate'
import { handleError } from '../utils/logger'

const { Title } = Typography

const RegistrationPage = () => {
  const { signInWithGoogle, register } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onFinish = async ({ email, password }) => {
    await register(email, password)
    notifySuccess({
      message: t('userRegistrationSuccessTitle'),
    })
  }
  return (
    <PublicPage>
      <FormContainer>
        <Title level={2}>
          <Translate name="registration" />
        </Title>
        <Form name="registration" onFinish={onFinish}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: t('invalidEmail'),
              },
              {
                required: true,
                message: t('missingEmail'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label={t('password')}
            rules={[
              {
                required: true,
                message: t('passwordMissing'),
              },
              () => ({
                validator(_, value) {
                  if (value.length < 6)
                    return Promise.reject(new Error(t('passwordMinLength')))
                  return Promise.resolve()
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={t('confirmPassword')}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: t('confirmPassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('passwordsMustMatch')))
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('Should accept agreement')),
              },
            ]}
          >
            <Checkbox>
              <Translate name="read" />{' '}
              <a href={process.env.REACT_APP_BASE_URL}>
                <Translate name="agreement" />
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <Translate name="register" />
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="secondary"
              htmlType="button"
              className="login-form-button"
              icon={<GoogleOutlined />}
              onClick={() => {
                signInWithGoogle()
                  .then(() => {
                    navigate(ROUTES.ACCOUNT)
                  })
                  .catch((e) =>
                    handleError({ error: e, message: 'GoogleLoginFailed' })
                  )
              }}
              block
            >
              <Translate name="loginWithGoogle" />
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
    </PublicPage>
  )
}

export default RegistrationPage
