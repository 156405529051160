const createAppointment = async ({
  date,
  time,
  graphicUid,
  currentUserUid,
  jobId,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/createEvent?date=${date}&time=${time}&graphicUid=${graphicUid}&userId=${currentUserUid}&jobId=${jobId}`
  )
  const responseData = await response.json()

  return responseData
}

export default createAppointment
