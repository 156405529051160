import { Button, DatePicker, Space, TimePicker } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Translate from '../../components/Translate'
import { useAuth } from '../../contexts/AuthContext'
import { useSetJob, useSetJobLog } from '../../hooks/dataLayer'
import useGraphicsAvailability from '../../hooks/useGraphicsAvailability'
import createAppointment from '../../utils/appointment'
import { JOB_EVENTS, JOB_STATUSES } from '../../utils/constants'
import { handleError } from '../../utils/logger'
import './index.css'

const StyledDatePicker = styled.div``

const TIME_DIFF = new Date().getTimezoneOffset() / -60
const TODAY = new Date()
const TODAY_FORMATTED = `${TODAY.getFullYear()}-${(TODAY.getMonth() + 1)
  .toString()
  .padStart(2, '0')}-${TODAY.getDate().toString().padStart(2, '0')}`

const CreateAppointment = ({ id, job }) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()

  const { data, loading } = useGraphicsAvailability()
  const { save, loading: isSaving } = useSetJob(id)
  const { save: saveJobLog } = useSetJobLog(id)

  const [availableHours, setAvailableHours] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [isAppointmentCreating, setIsAppointmentCreating] = useState(false)

  const disabledDate = (current) => {
    return !(data || []).some(
      ({ date }) => date === current.format('YYYY-MM-DD')
    )
  }

  const getDisabledHours = () => {
    const hours = []
    for (let i = 0; i < 24; i += 1) {
      if (TODAY_FORMATTED === selectedDate && i <= TODAY.getHours())
        hours.push(i)
      if (!availableHours.includes(i)) hours.push(i)
    }
    return hours
  }

  const getRandomGraphicForSelectedDateTime = () => {
    const availableGraphics = data
      .find(({ date }) => date === selectedDate)
      .times.find(({ hour }) => hour === Number(selectedTime)).graphics

    return availableGraphics[0]
  }

  return loading ? (
    <Translate name="loading" />
  ) : data?.length === 0 ? (
    <Translate name="jobPage.noAvailableGraphic" />
  ) : (
    <Space direction="vertical">
      <Space>
        <Translate name="jobPage.date" />
        <StyledDatePicker>
          <DatePicker
            disabledDate={disabledDate}
            showToday={false}
            placeholder={t('jobPage.selectDate')}
            onChange={(selDate) => {
              setAvailableHours(
                data
                  .find(({ date }) => date === selDate.format('YYYY-MM-DD'))
                  .times.map(({ hour }) => hour + TIME_DIFF)
              )
              setSelectedDate(selDate.format('YYYY-MM-DD'))
            }}
          />
        </StyledDatePicker>
        {availableHours.length > 0 && (
          <>
            <Translate name="jobPage.time" />
            <TimePicker
              format="HH"
              disabledHours={() => getDisabledHours()}
              showNow={false}
              onOk={(time) => {
                setSelectedTime(time.format('HH') - TIME_DIFF)
              }}
              hideDisabledOptions
              placeholder={t('jobPage.selectTime')}
            />
          </>
        )}
      </Space>
      {selectedTime !== null && (
        <Button
          loading={isAppointmentCreating || isSaving}
          onClick={async () => {
            try {
              setIsAppointmentCreating(true)

              const graphicUid = getRandomGraphicForSelectedDateTime()
              await createAppointment({
                jobId: id,
                date: selectedDate,
                time: selectedTime,
                graphicUid,
                currentUserUid: currentUser.uid,
              })
              await save({
                ...job,
                date: selectedDate,
                time: selectedTime,
                status: JOB_STATUSES.READY,
                graphicUid,
              })
              await saveJobLog({
                event: JOB_EVENTS.APPOINTMENT_CREATION,
              })
            } catch (err) {
              handleError({ error: err, message: 'saveError' })
            } finally {
              setIsAppointmentCreating(false)
            }
          }}
        >
          <Translate name="jobPage.reservate" />
        </Button>
      )}
    </Space>
  )
}
export default CreateAppointment
