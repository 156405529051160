import { useEffect } from 'react'
import { Button, Col, Divider, Form, Input, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSetJob, useSetJobLog } from '../../hooks/dataLayer'
import {
  DB_COLLECTIONS,
  JOB_EVENTS,
  JOB_STATUSES,
  NEW_ENTITY_ID,
  ROUTES,
} from '../../utils/constants'
import { notifySuccess } from '../../utils/notifications'
import Translate from '../../components/Translate'
import '../../translations/i18n'
import TitleTranslate from '../../components/TitleTranslate'
import { LeftFormContainer } from '../../components/SharedComponents'
import Files from './Files'
import TimerController from './TimerController'
import Actions from './Actions'
import Appointment from './Appointment'
// import JobTimeLine from './JobTimeline'

const { useForm } = Form

const ClientView = ({ id, job }) => {
  const [form] = useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isNew = id === NEW_ENTITY_ID

  const { save, loading: isSaving } = useSetJob(id)
  const { save: saveNewLog } = useSetJobLog(id)

  const onFinish = async (values) => {
    const { id: idFromSave } = await save({
      ...job,
      ...values,
      status: JOB_STATUSES.DRAFT,
    })

    await saveNewLog(
      isNew ? { event: JOB_EVENTS.CREATE } : { event: JOB_EVENTS.EDIT },
      generatePath(DB_COLLECTIONS.JOBLOGS, { id: idFromSave })
    )
    navigate(generatePath(ROUTES.JOB, { id: idFromSave }))
    notifySuccess({ message: t('saved') })
  }

  useEffect(() => {
    form.setFieldsValue(job)
  }, [job])

  return (
    <Row gutter={[24, 16]}>
      <Col lg={{ span: 12 }} md={{ span: 24 }}>
        <LeftFormContainer>
          <TitleTranslate name="shared.detail" level={4} />
          <Form form={form} onFinish={onFinish} name="job">
            <Form.Item label={t('title')} name="title">
              <Input placeholder={t('title')} />
            </Form.Item>

            <Form.Item label={t('description')} name="description">
              <Input.TextArea placeholder={t('description')} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isSaving}
                disabled={!isNew && job.status !== JOB_STATUSES.DRAFT}
              >
                {isNew ? (
                  <Translate name="shared.create" />
                ) : (
                  <Translate name="shared.save" />
                )}
              </Button>
            </Form.Item>
          </Form>
        </LeftFormContainer>
        {!isNew && (
          <>
            <Divider />
            <Appointment id={id} job={job} />
            <Divider />
            <TitleTranslate name="jobPage.files" level={4} />
            <Files jobId={id} job={job} />
          </>
        )}
      </Col>
      {!isNew && (
        <Col lg={{ span: 12 }} md={{ span: 24 }}>
          <Actions id={id} job={job} />
          <TitleTranslate level={4} name="jobPage.timer" />
          <TimerController id={id} job={job} />
          {/* Temporary hide */}
          {/* <Divider />
          <TitleTranslate level={4} name="jobPage.timeline" />
          <JobTimeLine jobId={id} /> */}
        </Col>
      )}
    </Row>
  )
}

export default ClientView
