import { useParams } from 'react-router-dom'
import PrivatePage from '../components/Layout/PrivatePage'
import { NEW_ENTITY_ID } from '../utils/constants'
import { useAuth } from '../contexts/AuthContext'
import Translate from '../components/Translate'
import '../translations/i18n'
import { useJob } from '../hooks/dataLayer'
import LoadingContainer from '../components/LoadingContainer'
import GraphicView from './Job/GraphicView'
import PublicView from './Job/PublicView'
import ClientView from './Job/ClientView'

const JobPage = () => {
  const { currentUser } = useAuth()
  const params = useParams()
  const { id } = params

  const { data, loading, error } = useJob(id)

  const isEdit = id === NEW_ENTITY_ID || data?.authorUid === currentUser.uid
  const isGrpahicView = data?.graphicUid === currentUser.uid

  return (
    <PrivatePage
      header={
        <>
          <Translate name="jobPage.title" />
          {' - '}
          {data?.title}
        </>
      }
    >
      <LoadingContainer loading={loading} error={error}>
        {isEdit ? (
          <ClientView job={data} id={id} />
        ) : isGrpahicView ? (
          <GraphicView job={data} id={id} />
        ) : (
          <PublicView job={data} id={id} />
        )}
      </LoadingContainer>
    </PrivatePage>
  )
}

export default JobPage
