import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  confirmPasswordReset,
  sendEmailVerification,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth'
import { ROUTES } from '../utils/constants'
import { auth } from '../utils/firebase'

const AuthContext = createContext({
  currentUser: null,
  signInWithGoogle: () => Promise.resolve(),
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
})

export const useAuth = () => useContext(AuthContext)

const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user || null)
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const register = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
    await sendEmailVerification(userCredential.user)

    return userCredential
  }

  const forgotPassword = (email) => {
    return sendPasswordResetEmail(auth, email, {
      url: `${process.env.REACT_APP_BASE_URL}${ROUTES.LOGIN}`,
    })
  }

  const resetPassword = (oobCode, newPassword) => {
    return confirmPasswordReset(auth, oobCode, newPassword)
  }

  const logout = () => {
    return signOut(auth)
  }

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider()
    return signInWithPopup(auth, provider)
  }

  const changePassword = async (currentPassword, newPassword) => {
    const emailCred = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    )

    await reauthenticateWithCredential(auth.currentUser, emailCred)

    updatePassword(auth.currentUser, newPassword)
  }

  const value = {
    currentUser,
    loading,
    signInWithGoogle,
    login,
    register,
    logout,
    forgotPassword,
    resetPassword,
    changePassword,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
